<template>
  <div class="main-container" v-if="!initLoading">
    <div class="tool-container">
      <div>
        <a-checkbox v-model:checked="jiduContactChecked">{{ $t('show_jidu_contact_info') }}</a-checkbox>
        <br />
        <a-radio-group v-model:value="languageValue">
          <a-radio :value="1">{{ $t('preview_chi_eng_version') }}</a-radio>
          <a-radio :value="2">{{ $t('preview_eng_version') }}</a-radio>
        </a-radio-group>
      </div>
      <div>
        <a-button type="primary" v-print="printObj">{{ $t('print') }}</a-button>&nbsp;<a-button type="primary" @click="downloadPdf">{{ $t('download_pdf') }}</a-button>
      </div>
    </div>
    <a-divider/>
    <div class="print-container">
      <div id="print-container">
        <template v-if="type === 'voucher'">
          <template v-if="languageValue === 1">
            <div class="large-size"><strong>酒店入住单</strong> <strong class="copy">Hotel Voucher</strong></div>
            <div class="line-height">请在抵达时出示本单据 <span class="copy">Please present this voucher upon arrival.</span></div>
            <hr class="divider"/>
            <template v-if="!isEmpty(orderInfo.confirm_no)">
              <div class="line-height">酒店确认号 <span class="copy"> Hotel Confirmation Code</span>: <strong>{{ orderInfo.confirm_no }}</strong></div>
            </template>
            <table class="table-border">
              <thead>
                <tr>
                  <td class="line-height title">酒店信息 <span class="copy">Hotel Information</span></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="line-height"><strong class="medium-size">{{ orderInfo.hotel_name_cn + ' ' + orderInfo.hotel_name_en }}</strong></div>
                    <div class="line-height" v-if="!isEmpty(orderInfo.hotel_tel)"><span class="copy">电话 Telephone:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ orderInfo.hotel_tel }}</div>
                    <div class="line-height"><span class="copy">地址 Address:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ isEmpty(orderInfo.hotel_address_cn) ? orderInfo.hotel_address_en : orderInfo.hotel_address_cn }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table-border">
              <thead>
                <tr>
                  <td class="line-height title" colspan="3">订单信息 <span class="copy">Order Information</span></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="width:37%">
                    <div class="line-height">订单号 <span class="copy">Booking Id</span></div>
                    <div class="line-height">{{ orderInfo.order_id }}</div>
                  </td>
                  <td style="width:33%">
                    <div class="line-height">入住日期 <span class="copy">Check-In</span></div>
                    <div class="line-height">{{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }}</div>
                  </td>
                  <td style="width:30%">
                    <div class="line-height">离店日期 <span class="copy">Check-Out</span></div>
                    <div class="line-height">{{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="line-height">房型 <span class="copy">Room Type</span></div>
                    <div class="line-height" v-if="!isEmpty(orderInfo.room_name_cn)">{{ orderInfo.room_name_cn }}</div>
                    <div class="line-height" v-if="!isEmpty(orderInfo.room_name_en)">{{ orderInfo.room_name_en }}</div>
                    <div class="line-height" v-else>{{ orderInfo.room_name }}</div>
                  </td>
                  <td>
                    <div class="line-height">房间数 <span class="copy">Unit</span></div>
                    <div class="line-height">{{ orderInfo.PaxRoom.Count }}</div>
                  </td>
                  <td>
                    <div class="line-height">餐型 <span class="copy">Meal Type</span></div>
                    <div class="line-height">{{ $t('mealtype_unit_cn.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}</div>
                    <div class="line-height">{{ $t('mealtype_unit_en.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="line-height">每间入住 <span class="copy">Per Room</span></div>
                    <div class="line-height">
                      {{ $t('adult_unit_cn', {'n': orderInfo.PaxRoom.Adults}) }},
                      {{ $t('child_unit_cn', {'n': orderInfo.PaxRoom?.Children?.length}) }}
                      <template v-if="orderInfo.PaxRoom?.Children?.length > 0">
                        (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old_cn', {n: age}) }}</template>)
                      </template>
                    </div>
                    <div class="line-height">
                      {{ $t('adult_unit_en', {'n': orderInfo.PaxRoom.Adults}) }},
                      {{ $t('child_unit_en', {'n': orderInfo.PaxRoom?.Children?.length}) }}
                      <template v-if="orderInfo.PaxRoom?.Children?.length > 0">
                        (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old_en', {n: age}) }}</template>)
                      </template>
                    </div>
                  </td>
                  <td colspan="2">
                    <div class="line-height">入住人（名/姓）<span class="copy">Guests(First / Last)</span></div>
                    <div class="line-height"><template v-for="(name,nameIndex) in orderInfo.PaxRoom.PaxNames" v-bind:key="nameIndex">
                      {{name.FirstName}} {{name.LastName}};
                    </template></div>
                  </td>
                </tr>
                <tr v-if="!isEmpty(orderInfo.special)">
                  <td colspan="3">
                    <div class="line-height">特殊需求 <span class="copy">Customer Requests</span></div>
                    <div class="line-height">{{ orderInfo.special }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="tip">
              <div class="line-height">温馨提示 :</div>
              <div class="line-height">1. 请您于酒店前台办理入住手续时出示您的有效护照证件或提供您护照证件上的英文名，以便酒店前台准确查询到您的预订信息（英文名顺序为名/姓 例如 ming/zhang）</div>
              <div class="line-height">2. 请您于酒店前台办理入住手续时向前台明确你所需要的床型，酒店将尽量为您安排（大床房DOUBLE/KING 双床房TWINS）</div>
              <div class="line-height">3. 所有特殊申请需于酒店前台办理入住手续时以酒店最终确认为准</div>
              <div class="line-height">4. 如需延迟入住或于22:00后到店办理入住，请提前联系酒店确认，否则有可能被酒店视作自动放弃房间保留权（房费均不予以退还）</div>
              <div class="line-height">5. 如房费包含早餐，部分酒店可能会对随行儿童收取额外的早餐费用，具体情况以酒店核实为准</div>
              <div class="line-height">6. 您的此次入住已含房费及一般税费，不包含城市税（欧洲）、度假村费、设施费（北美）、市政费（迪拜）、销售与服务费（SST，马来西亚）等酒店向您另行征收的费用；您在酒店产生的额外消费、增项服务费、违规罚款等一切费用，需您跟酒店另行结算</div>
            </div>
            <template v-if="jiduContactChecked">
              <hr class="divider"/>
              <div class="jidu-info">
                <div>
                  如果您对此订单有有任何疑问，请通过以下方式联系我们：<br />
                  电话: 15541156694 (仅限紧急联系) <br />
                  Email: cs@jidutravel.com
                </div>
                <a-image src="/images/logo_zh_CN.png" :preview="false" :width="150"></a-image>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="large-size"><strong>Hotel Voucher</strong></div>
            <div class="line-height">Please present this voucher upon arrival.</div>
            <hr class="divider"/>
            <template v-if="!isEmpty(orderInfo.confirm_no)">
              <div class="line-height">Hotel Confirmation Code: <strong>{{ orderInfo.confirm_no }}</strong></div>
            </template>
            <table class="table-border">
              <thead>
              <tr>
                <td class="line-height title">Hotel Information</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <div class="line-height"><strong class="medium-size">{{ orderInfo.hotel_name_en }}</strong></div>
                  <div class="line-height" v-if="!isEmpty(orderInfo.hotel_tel)"><span class="copy">Telephone:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ orderInfo.hotel_tel }}</div>
                  <div class="line-height"><span class="copy">Address:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ orderInfo.hotel_address_en }}</div>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="table-border">
              <thead>
              <tr>
                <td class="line-height title" colspan="3">Order Information</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style="width:37%">
                  <div class="line-height">Booking Id</div>
                  <div class="line-height">{{ orderInfo.order_id }}</div>
                </td>
                <td style="width:33%">
                  <div class="line-height">Check-In</div>
                  <div class="line-height">{{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }}</div>
                </td>
                <td style="width:30%">
                  <div class="line-height">Check-Out</div>
                  <div class="line-height">{{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="line-height">Room Type</div>
                  <div class="line-height" v-if="!isEmpty(orderInfo.room_name_en)">{{ orderInfo.room_name_en }}</div>
                  <div class="line-height" v-else>{{ orderInfo.room_name }}</div>
                </td>
                <td>
                  <div class="line-height">Unit</div>
                  <div class="line-height">{{ orderInfo.PaxRoom.Count }}</div>
                </td>
                <td>
                  <div class="line-height">Meal Type</div>
                  <div class="line-height">{{ $t('mealtype_unit_en.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="line-height">Per Room</div>
                  <div class="line-height">
                    {{ $t('adult_unit_en', {'n': orderInfo.PaxRoom.Adults}) }},
                    {{ $t('child_unit_en', {'n': orderInfo.PaxRoom?.Children?.length}) }}
                    <template v-if="orderInfo.PaxRoom?.Children?.length > 0">
                      (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old_en', {n: age}) }}</template>)
                    </template>
                  </div>
                </td>
                <td colspan="2">
                  <div class="line-height">Guests(First / Last)</div>
                  <div class="line-height"><template v-for="(name,nameIndex) in orderInfo.PaxRoom.PaxNames" v-bind:key="nameIndex">
                    {{name.FirstName}} {{name.LastName}};
                  </template></div>
                </td>
              </tr>
              <tr v-if="!isEmpty(orderInfo.special)">
                <td colspan="3">
                  <div class="line-height">Customer Requests</div>
                  <div class="line-height">{{ orderInfo.special }}</div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="tip">
              <div class="line-height">Reminder :</div>
              <div class="line-height">1. Upon your arrival please provide valid government-issued ID to the hotel front desk to locate the accurate booking.</div>
              <div class="line-height">2. Please tell front desk agent your preferred bed type if your booking comes with more than one (e.g. Double or Twin). The final arrangement is fully subject to hotel’s availability.</div>
              <div class="line-height">3. All special requests are not guaranteed. Please confirm your special requests with front desk upon arrival.</div>
              <div class="line-height">4. Please contact your hotel in advance should you need a late check-in after 10pm on your check-in date, or your booking might be canceled by hotel (with no refund).</div>
              <div class="line-height">5. Please be noted some hotels charge extra breakfast fee for children even when your room offers breakfast. The actual situation is subject to the hotel regulations.</div>
              <div class="line-height">6. Regular tax and fees are included in this stay. Addtional charges (City tax (Europe), resort fees, facility fees (North America), municipal fee (Dubai), tourist tax (Malaysia),Sales & Services Tax(SST,Malaysia), etc.) may be charged directly by the hotel; Any other fees occured in the hotel such as addtional service fees, violation fines will also be cahrged by the hotel directly.</div>
            </div>
            <template v-if="jiduContactChecked">
              <hr class="divider"/>
              <div class="jidu-info">
                <div>
                  Should you have any questions about this booking, please contact us via:<br />
                  Tel: 15541156694 (Emergency contact only) <br />
                  Email: cs@jidutravel.com
                </div>
                <a-image src="/images/logo_en_US.png" :preview="false" :width="150"></a-image>
              </div>
            </template>
          </template>
        </template>
        <template v-else>
          <template v-if="languageValue === 1">
            <div class="large-size"><strong>酒店确认函</strong> <strong class="copy">Hotel Confirmation Letter</strong></div>
            <hr class="divider"/>
            <div class="line-height">订单总价 <span class="copy">Total Cost</span>: <strong>{{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}</strong></div>
            <div class="line-height" v-if="orderInfo.source_id !== orderInfo.orderId">客户单号 <span class="copy">Client ID</span>: <strong>{{ orderInfo.source_id }}</strong></div>
            <div class="line-height" v-if="!isEmpty(orderInfo.source_id1)">团单号 <span class="copy">Group Booking ID</span>: <strong>{{ orderInfo.source_id1 }}</strong></div>
            <table class="table-border">
              <thead>
              <tr>
                <td class="line-height title" colspan="3">订单信息 <span class="copy">Order Information</span></td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style="width:37%">
                  <div class="line-height">订单号 <span class="copy">Booking Id</span></div>
                  <div class="line-height">{{ orderInfo.order_id }}</div>
                </td>
                <td style="width:33%">
                  <div class="line-height">入住日期 <span class="copy">Check-In</span></div>
                  <div class="line-height">{{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }}</div>
                </td>
                <td style="width:30%">
                  <div class="line-height">离店日期 <span class="copy">Check-Out</span></div>
                  <div class="line-height">{{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="line-height">房型 <span class="copy">Room Type</span></div>
                  <div class="line-height" v-if="!isEmpty(orderInfo.room_name_cn)">{{ orderInfo.room_name_cn }}</div>
                  <div class="line-height" v-if="!isEmpty(orderInfo.room_name_en)">{{ orderInfo.room_name_en }}</div>
                  <div class="line-height" v-else>{{ orderInfo.room_name }}</div>
                </td>
                <td>
                  <div class="line-height">房间数 <span class="copy">Unit</span></div>
                  <div class="line-height">{{ orderInfo.PaxRoom.Count }}</div>
                </td>
                <td>
                  <div class="line-height">餐型 <span class="copy">Meal Type</span></div>
                  <div class="line-height">{{ $t('mealtype_unit_cn.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}</div>
                  <div class="line-height">{{ $t('mealtype_unit_en.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="line-height">每间入住 <span class="copy">Per Room</span></div>
                  <div class="line-height">
                    {{ $t('adult_unit_cn', {'n': orderInfo.PaxRoom.Adults}) }},
                    {{ $t('child_unit_cn', {'n': orderInfo.PaxRoom?.Children?.length}) }}
                    <template v-if="orderInfo.PaxRoom?.Children?.length > 0">
                      (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old_cn', {n: age}) }}</template>)
                    </template>
                  </div>
                  <div class="line-height">
                    {{ $t('adult_unit_en', {'n': orderInfo.PaxRoom.Adults}) }},
                    {{ $t('child_unit_en', {'n': orderInfo.PaxRoom?.Children?.length}) }}
                    <template v-if="orderInfo.PaxRoom?.Children?.length > 0">
                      (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old_en', {n: age}) }}</template>)
                    </template>
                  </div>
                </td>
                <td colspan="2">
                  <div class="line-height">入住人（名/姓）<span class="copy">Guests(First / Last)</span></div>
                  <div class="line-height"><template v-for="(name,nameIndex) in orderInfo.PaxRoom.PaxNames" v-bind:key="nameIndex">
                    {{name.FirstName}} {{name.LastName}};
                  </template></div>
                </td>
              </tr>
              <tr v-if="!isEmpty(orderInfo.special)">
                <td colspan="3">
                  <div class="line-height">特殊需求 <span class="copy">Customer Requests</span></div>
                  <div class="line-height">{{ orderInfo.special }}</div>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="table-border">
              <thead>
              <tr>
                <td class="line-height title">酒店信息 <span class="copy">Hotel Information</span></td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <div class="line-height"><strong class="medium-size">{{ orderInfo.hotel_name_cn + ' ' + orderInfo.hotel_name_en }}</strong></div>
                  <div class="line-height" v-if="!isEmpty(orderInfo.hotel_tel)"><span class="copy">电话 Telephone:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ orderInfo.hotel_tel }}</div>
                  <div class="line-height"><span class="copy">地址 Address:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ isEmpty(orderInfo.hotel_address_cn) ? orderInfo.hotel_address_en : orderInfo.hotel_address_cn }}</div>
                </td>
              </tr>
              </tbody>
            </table>
            <template v-if="jiduContactChecked">
              <hr class="divider"/>
              <div class="jidu-info">
                <div>
                  如果您对此订单有有任何疑问，请通过以下方式联系我们：<br />
                  电话: 15541156694 (仅限紧急联系) <br />
                  Email: cs@jidutravel.com
                </div>
                <a-image src="/images/logo_zh_CN.png" :preview="false" :width="150"></a-image>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="large-size"><strong>Hotel Confirmation Letter</strong></div>
            <hr class="divider"/>
            <div class="line-height">Total Cost: <strong>{{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}</strong></div>
            <div class="line-height" v-if="orderInfo.source_id !== orderInfo.orderId">Client ID: <strong>{{ orderInfo.source_id }}</strong></div>
            <div class="line-height" v-if="!isEmpty(orderInfo.source_id1)">Group Booking ID: <strong>{{ orderInfo.source_id1 }}</strong></div>
            <table class="table-border">
              <thead>
              <tr>
                <td class="line-height title" colspan="3">Order Information</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style="width:37%">
                  <div class="line-height">Booking Id</div>
                  <div class="line-height">{{ orderInfo.order_id }}</div>
                </td>
                <td style="width:33%">
                  <div class="line-height">Check-In</div>
                  <div class="line-height">{{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }}</div>
                </td>
                <td style="width:30%">
                  <div class="line-height">Check-Out</div>
                  <div class="line-height">{{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="line-height">Room Type</div>
                  <div class="line-height" v-if="!isEmpty(orderInfo.room_name_en)">{{ orderInfo.room_name_en }}</div>
                  <div class="line-height" v-else>{{ orderInfo.room_name }}</div>
                </td>
                <td>
                  <div class="line-height">Unit</div>
                  <div class="line-height">{{ orderInfo.PaxRoom.Count }}</div>
                </td>
                <td>
                  <div class="line-height">Meal Type</div>
                  <div class="line-height">{{ $t('mealtype_unit_en.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="line-height">Per Room</div>
                  <div class="line-height">
                    {{ $t('adult_unit_en', {'n': orderInfo.PaxRoom.Adults}) }},
                    {{ $t('child_unit_en', {'n': orderInfo.PaxRoom?.Children?.length}) }}
                    <template v-if="orderInfo.PaxRoom?.Children?.length > 0">
                      (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old_en', {n: age}) }}</template>)
                    </template>
                  </div>
                </td>
                <td colspan="2">
                  <div class="line-height">Guests(First / Last)</div>
                  <div class="line-height"><template v-for="(name,nameIndex) in orderInfo.PaxRoom.PaxNames" v-bind:key="nameIndex">
                    {{name.FirstName}} {{name.LastName}};
                  </template></div>
                </td>
              </tr>
              <tr v-if="!isEmpty(orderInfo.special)">
                <td colspan="3">
                  <div class="line-height">Customer Requests</div>
                  <div class="line-height">{{ orderInfo.special }}</div>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="table-border">
              <thead>
              <tr>
                <td class="line-height title">Hotel Information</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <div class="line-height"><strong class="medium-size">{{ orderInfo.hotel_name_en }}</strong></div>
                  <div class="line-height" v-if="!isEmpty(orderInfo.hotel_tel)"><span class="copy">Telephone:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ orderInfo.hotel_tel }}</div>
                  <div class="line-height"><span class="copy">Address:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ orderInfo.hotel_address_en }}</div>
                </td>
              </tr>
              </tbody>
            </table>
            <template v-if="jiduContactChecked">
              <hr class="divider"/>
              <div class="jidu-info">
                <div>
                  Should you have any questions about this booking, please contact us via:<br />
                  Tel: 15541156694 (Emergency contact only) <br />
                  Email: cs@jidutravel.com
                </div>
                <a-image src="/images/logo_en_US.png" :preview="false" :width="150"></a-image>
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>
    <a-divider/>
    <div class="tool-container">
      <div>
      </div>
      <div>
        <a-button type="primary" v-print="printObj">{{ $t('print') }}</a-button>&nbsp;<a-button type="primary" @click="downloadPdf">{{ $t('download_pdf') }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import print from 'vue3-print-nb'
import {inject} from "vue";
import {Modal} from "ant-design-vue";
import dayjs from "dayjs";
import {isEmpty,getEmptyValue} from "@/utils/util";
import {getInfo} from "@/services/booking";
import {OrderHotelStatus} from "@/data/order-hotel-status";
import html2Canvas from 'html2canvas'
import jsPDF from 'jspdf'

export default {
  name: "BookingVoucher",
  directives: {
    print
  },
  data(){
    const orderId = this.$route.params?.OrderId
    if(isEmpty(orderId))
      location.href = '/home'

    let type = this.$route.query?.Type
    if(isEmpty(type))
      type = 'voucher'

    document.title = (type === 'voucher' ? this.$t('hotel_voucher') : this.$t('hotel_confirmation_letter')) + ' ' + orderId + ' - ' + this.$t('app_name')

    getInfo(orderId, this.$store.getters["setting/getLanguage"]).then(res => {
      this.initLoading = false
      const resData = res.data
      if (resData.Success) {
        this.orderInfo = resData.Data
        this.orderInfo.PaxRoom = JSON.parse(this.orderInfo.pax_room_json)
        if(!this.orderInfo.PaxRoom.Children)
          this.orderInfo.PaxRoom.Children = []
      } else {
        Modal.error({
          centered: true,
          title: this.$t('error.' + resData.Code),
        });
      }
    }).catch(error => {
      console.log(error)
    })

    const isZhCn = this.$store.getters["setting/getLanguage"] === 'zh_CN'
    const languageValue = isZhCn?1:2

    return {
      dayjs,
      checkInOutFormat: 'YYYY-MM-DD',
      showHeaderFooter: inject('showHeaderFooter'),
      isZhCn,
      orderId,
      type,
      initLoading: true,
      orderInfo: undefined,
      OrderHotelStatus,
      jiduContactChecked: true,
      languageValue,
      printObj: {
        id: 'print-container',
        previewTitle: document.title,
        popTitle: document.title,
      },
    }
  },
  beforeMount() {
    this.showHeaderFooter = false
  },
  methods: {
    isEmpty,getEmptyValue,
    getPdf(title) {
      html2Canvas(document.querySelector('#print-container'), {
        allowTaint: false,
        taintTest: false,
        logging: false,
        useCORS: true,
        dpi: window.devicePixelRatio*4, //将分辨率提高到特定的DPI 提高四倍
        scale:4 //按比例增加分辨率
      }).then(canvas=>{
        let pdf = new jsPDF('p', 'mm', 'a4');    //A4纸，纵向
        let ctx = canvas.getContext('2d'),
            a4w = 190, a4h = 277,    //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
            imgHeight = Math.floor(a4h * canvas.width / a4w),    //按A4显示比例换算一页图像的像素高度
            renderedHeight = 0;

        while(renderedHeight < canvas.height) {
          let page = document.createElement("canvas");
          page.width = canvas.width;
          page.height = Math.min(imgHeight, canvas.height - renderedHeight);//可能内容不足一页

          //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
          page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
          pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width));    //添加图像到页面，保留10mm边距

          renderedHeight += imgHeight;
          if(renderedHeight < canvas.height) {
            pdf.addPage();//如果后面还有内容，添加一个空页
          }
          // delete page;
        }
        //保存文件
        pdf.save(title + '.pdf')
      })
    },
    downloadPdf(){
      this.getPdf(document.title)
    }
  }
}
</script>

<style scoped>
.main-container {
  max-width: 750px;
  margin: 20px auto;
  font-size: 12px;
  line-height: 25px;
}
.main-container .ant-divider-horizontal {
  margin: 0 0 10px 0;
}
.tool-container {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items:center;
}
.print-container {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.divider {
  border: 0;
  height: 0;
  border-bottom: 2px solid #000000;
}
.copy {
  color: rgba(0, 0, 0, 0.7);
}
.large-size {
  font-size: 16px;
}
.medium-size {
  font-size: 14px;
}
.title {
  background-color: #FAFAFA;
}
.table-border {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 10px;
}
.table-border td {
  padding: 5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  vertical-align: top;
}
.line-height {
  line-height: 25px;
}
.tip {
  padding: 10px 20px;
}
.jidu-info {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  line-height: 20px;
}
</style>